<script>
  import { wsx } from "@axel669/zephyr";
  import { Screen, Paper, Flex, Titlebar, Text} from "@axel669/zephyr";

</script>

<svelte:body use:wsx={{ "@@theme": 'dark', "@@app": true, "p": "8px" }} />

<Screen>
  <Paper>
    <Titlebar slot="header">
      <Flex p="0px" gap="0px" slot="title">
        <Text title> The Test App! </Text>
        <Text subtitle> It's not all titties and beer </Text>
      </Flex>
    </Titlebar>

    <Titlebar slot="footer">
      <Text slot="title" title>
        <Text subtitle> It's only KINDA titties and beer </Text>
      </Text>
    </Titlebar>
  </Paper>
</Screen>